import { 
  UilChart, UilUsersAlt, UilCalendarAlt, UilNewspaper, UilUserPlus, 
  UilUtensils, UilGraduationCap, UilBookOpen, UilFileAlt, UilClock, 
  UilUserTimes, UilComments, UilAward, UilFileCheck, UilCreditCard, 
  UilMoneyBill, UilUser, UilUserMd 
} from '@iconscout/react-unicons';

import { Drawer, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

function MenueItems({ isOpen, onClose }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { role, data } = useSelector((state) => {
    return {
      role: state.auth.role,
      data: state.auth.data,
    };
  });

  const path = `/${role}`;

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const items =
    role === 'admin'
      ? [
          getItem(t('Statistiques'), 'statistques', <UilChart />, [
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/statistics/inscriptions`}
              >
                {t('Generales')}
              </NavLink>,
              'General',
            ),
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/statistics/clubs`}
              >
                {t('clubs')}
              </NavLink>,
              'clubsstates',
            ),
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/statistics/moyennes`}
              >
                {t('Moyennes')}
              </NavLink>,
              'moyennes',
            ),
          ]),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/clubs`}
            >
              {t('Clubs & Activités')}
            </NavLink>,
            'clubs',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/clubs`}
            >
              <UilUsersAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/events`}
            >
              {t('Evènements')}
            </NavLink>,
            'events',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/events`}
            >
              <UilCalendarAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/actualite`}
            >
              {t('actualite')}
            </NavLink>,
            'actualite',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/actualite`}
            >
              <UilNewspaper />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/inscription`}
            >
              {t('inscription')}
            </NavLink>,
            'inscription',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/inscription`}
            >
              <UilUserPlus />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/cantine`}
            >
              {t('cantine')}
            </NavLink>,
            'cantine',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/cantine`}
            >
              <UilUtensils />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/classes`}
            >
              {t('Classes')}
            </NavLink>,
            'classes',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/classes`}
            >
              <UilGraduationCap />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/matiere`}
            >
              {t('matieres')}
            </NavLink>,
            'matiere',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/matiere`}
            >
              <UilBookOpen />
            </NavLink>,
          ),
          data.type === 2 &&
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/devoir`}
              >
                {t('devoirs')}
              </NavLink>,
              'devoir',
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                className="menuItem-iocn"
                to={`${path}/devoir`}
              >
                <UilFileAlt />
              </NavLink>,
            ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/emploi`}
            >
              {t('emploi du temps')}
            </NavLink>,
            'emploi',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/emploi`}
            >
              <UilClock />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/absenceeleve`}
            >
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/absenceeleve`}
            >
              <UilUserTimes />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/reunion`}
            >
              {t('Réunion')}
            </NavLink>,
            'reunion',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/reunion`}
            >
              <UilComments />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/absenceprofs`}
            >
              {t('absence profs')}
            </NavLink>,
            'absenceprofs',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/absenceprofs`}
            >
              <UilUserTimes />
            </NavLink>,
          ),
          data.type === 2 &&
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/notes`}
              >
                {t('notes')}
              </NavLink>,
              'notes',
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                className="menuItem-iocn"
                to={`${path}/notes`}
              >
                <UilAward />
              </NavLink>,
            ),
          data.type === 2 &&
            getItem(
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                to={`${path}/bulletin`}
              >
                {t('bulletins')}
              </NavLink>,
              'bulletin',
              <NavLink
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                onClick={onClose}
                className="menuItem-iocn"
                to={`${path}/bulletin`}
              >
                <UilFileCheck />
              </NavLink>,
            ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/paymentmethods`}
            >
              {t('methodes de payment')}
            </NavLink>,
            'paymentmethods',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/paymentmethods`}
            >
              <UilCreditCard />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/personnel`}
            >
              {t('personnel')}
            </NavLink>,
            'personnel',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/personnel`}
            >
              <UilUsersAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/charge`}
            >
              {t('charge')}
            </NavLink>,
            'charge',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/charge`}
            >
              <UilMoneyBill />
            </NavLink>,
          ),
        ]
      : role === 'parents'
      ? [
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/profile/timeline`}
            >
              {t('profil')}
            </NavLink>,
            'profile',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/profile/timeline`}
            >
              <UilUser />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/calendar`}
            >
              {t('calendrier')}
            </NavLink>,
            'calendar',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/calendar`}
            >
              <UilCalendarAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/emploi`}
            >
              {t('emploi')}
            </NavLink>,
            'emploi',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/emploi`}
            >
              <UilClock />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/clubs`}
            >
              {t('clubs')}
            </NavLink>,
            'clubs',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/clubs`}
            >
              <UilUsersAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/events`}
            >
              {t('events')}
            </NavLink>,
            'events',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/events`}
            >
              <UilCalendarAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/cantine`}
            >
              {t('cantine')}
            </NavLink>,
            'cantine',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/cantine`}
            >
              <UilUtensils />
            </NavLink>,
          ),
        ]
      : role === 'professeur'
      ? [
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}`}
            >
              {t('absence elève')}
            </NavLink>,
            'absenceeleve',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}`}
            >
              <UilUserTimes />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/exercices`}
            >
              {t('exercices')}
            </NavLink>,
            'exercices',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/exercices`}
            >
              <UilFileAlt />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/bulletin`}
            >
              {t('bulletins')}
            </NavLink>,
            'bulletin',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/bulletin`}
            >
              <UilFileCheck />
            </NavLink>,
          ),
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/notes`}
            >
              {t('notes')}
            </NavLink>,
            'notes',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/notes`}
            >
              <UilAward />
            </NavLink>,
          ),
        ]
      : [
          getItem(
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              to={`${path}/`}
            >
              {t('admins')}
            </NavLink>,
            'admins',
            <NavLink
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
              onClick={onClose}
              className="menuItem-iocn"
              to={`${path}/`}
            >
              <UilUserMd />
            </NavLink>,
          ),
        ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items);

  const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23']);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <Drawer title={null} placement={'left'} closable={false} onClose={onClose} open={isOpen}>
      <Menu mode="inline" openKeys={stateOpenKeys} onOpenChange={onOpenChange} items={items} />
    </Drawer>
  );
}

export default MenueItems;